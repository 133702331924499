import React, { createContext, useState } from 'react';

export const DecisionsContext = createContext({
  decisions: null,
});

export const SetDecisionsContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  setDecisions: (decisions: any) => {},
});

export function DecisionsProvider({ children }: { children: React.ReactNode }) {
  const [decisions, setDecisions] = useState(null);

  const DecisionsContextValue = React.useMemo(
    () => ({
      decisions,
    }),
    [decisions],
  );

  const SetDecisionsContextValue = React.useMemo(
    () => ({
      setDecisions,
    }),
    [setDecisions],
  );

  return (
    <SetDecisionsContext.Provider value={SetDecisionsContextValue}>
      <DecisionsContext.Provider value={DecisionsContextValue}>{children}</DecisionsContext.Provider>
    </SetDecisionsContext.Provider>
  );
}
