import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const LandingPage = lazy(() => import('pages/LandingPage'));
const DecisionListPage = lazy(() => import('pages/DecisionListPage'));
const DecisionDetailPage = lazy(() => import('pages/DecisionDetailPage'));

function App() {
  return (
    <Router basename="/dtp">
      <Suspense fallback={null}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/decisions" element={<DecisionListPage />} />
          <Route path="/decisions/:queryId" element={<DecisionListPage />} />
          <Route path="/decisions/:queryId/:decisionId" element={<DecisionDetailPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
