import './public-path';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { setMetricNamespace } from '@amzn/tax-platform-console-metrics';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DecisionsProvider } from './contexts/DecisionsContext';

setMetricNamespace('DTP');
const getSubRootContainer = (container: any) => {
  return container ? container.querySelector('#microapp-dtp') : document.querySelector('#microapp-dtp');
};

function render(props: any) {
  const { container } = props;
  ReactDOM.render(
    <React.StrictMode>
      <DecisionsProvider>
        <App />
      </DecisionsProvider>
    </React.StrictMode>,
    getSubRootContainer(container),
  );
}

if (!(window as any).__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {
  return null;
}

export async function mount(props: any) {
  render(props);
}

export async function unmount(props: any) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(getSubRootContainer(container));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
